import { useNavigate, useParams } from "react-router-dom";

import { useInfoDay } from "../../hooks/useInfoDay";
import { GameInformation } from "../GameInformation";
import { openOathLaLiga } from "../../services/User";
import { Loading } from "../Loading";

import { BACKEND_API } from "../../constants";
import { getAccessTokenFromLocalStorage } from "../../services/Token";

export const Home = () => {
  const { team } = useParams()
  const navigate = useNavigate()
  const { date, teams, loading, filterByAttribute, validateTeam } = useInfoDay(team);

  const handleClick = async() => {
    const access_token = getAccessTokenFromLocalStorage();
    if (access_token) navigate(`/${team}/stream`)
    else {
      await openOathLaLiga(`${BACKEND_API}/oauth/login?team=${team}`)
      .then(response => {
        const { url } = response
        window.location.href = url
      })
    }
  }

  if (!validateTeam()) navigate(`/404`)

  return (
    <div className="home-container">
      <header />
      <section className="home-container__intro">
          {
            loading
            ? <Loading />
            : <>
                <GameInformation
                  teams={teams}
                  date={date}
                  filterByAttribute={filterByAttribute}
                />
                <footer onClick={handleClick}>
                  <span>Continuar</span>
                </footer>
            </>
          }
      </section>
      <div className="terms-and-conditions">
        <a href="/informacion_legal.pdf" target="_blank">Información legal</a>
        <a href="/politica_privacidad.pdf" target="_blank">Política de privacidad</a>
      </div>
      <img src="/logo-zona-fans-footer.svg" alt="Zona fan." />
    </div>
  );
};
