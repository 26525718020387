const urlParams = new URLSearchParams(window.location.search);
const queryCode = urlParams.get("code");
const queryState: any = urlParams.get("state");
const path = JSON.parse(queryState)?.path;

export function NoMatch() {
  const handleView = () => {
    if (queryCode && path) {
      window.location.href = `/${path}/stream?code=${queryCode}`;
      return <></>;
    } else {
      return (
        <div className="home-container">
          <header />
          <section className="home-container__no-match">
            <div>
              <h3>No hay horarios confirmados para los pr&oacute;ximos partidos.</h3>
              <h4>
                Consulta las emisiones de <strong>La Liga</strong> multi
                pantalla en <strong>Movistar+</strong> y <strong>DAZN</strong>
              </h4>
            </div>
          </section>
        </div>
      );
    }
  };

  return handleView();
}
